import React, { ReactDOM } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { RoutePropsConfig } from './config';
import KeepAlive from 'react-activation';
import { getLocal } from 'util/comm';
import IframePage from 'view/iframePage';
import { toJS } from 'mobx';

export interface RoutesProps {
  routers: MyRouteProps[];
  iframeClass?: string;
}

export interface MyRouteProps extends RoutePropsConfig {
  id: string;
  parentId: string;
  uiRoute: string;
  button: string[];
}

// 需要忽略token的路由
// const filterPath: string[] = ['login']

/**
 * 验证token
 * @param component
 * @returns 返回相应的组件 或跳转登录
 */
export const requireLogin = (component: ReactDOM | React.Component | JSX.Element): ReactDOM | React.Component | JSX.Element => {
  const token: string | null = getLocal('tx_token');
  return token && token !== 'undefined' ? component : <Redirect to='/login' />;
  // return component
};

export const ChidrenRouters = (props: MyRouteProps) => {
  const { id, name, component, children, redirect, pagePath, ...extra } = props;
  const Component: any = component;

  return (
    <Route
      key={name}
      path={extra.path}
      render={(selfProps: any) => {
        return requireLogin(
          props.isIframe === true ? (
            <IframePage pagePath={pagePath!} name={name} classname='view-tabs' />
          ) : redirect ? (
            <>
              {children ? ChidrenRouters(children as any) : null}
              <Redirect exact from={props.path} to={redirect} />
            </>
          ) : (
            <KeepAlive id={props.name}>
              <Component {...extra} {...selfProps} />
            </KeepAlive>
          )
        );
      }}
    />
  );
};

const SelfRoute = (props: MyRouteProps) => {
  const { id, uiRoute, component, isTabs, pagePath, button, ...extra } = props;
  const Component: any = component;
  return (
    <Route
      key={id}
      path={props.path}
      render={(selfProps: any) => {
        const { location } = selfProps;
        location.state = {
          authorization: toJS(button),
        };
        return requireLogin(
          props.isIframe === true ? (
            <IframePage pagePath={pagePath!} name={uiRoute} />
          ) : (
            <KeepAlive name={props.name}>
              <Component {...extra} {...selfProps} />
            </KeepAlive>
          )
        );
      }}
    />
  );
};

const router = (route: MyRouteProps[], _notFind: boolean = false) => {
  return (
    <Switch>
      {route.map((router: MyRouteProps, index: number) => {
        if (router.redirect) {
          // 这个Redirect原来在SelfRoute里面，但是放在那里面的话，写的exact和from根本就没有起作用，会无条件重定向到redirect
          return <Redirect key={'redirect' + index} exact from={router.path} to={router.redirect} />;
        }
        return null;
      })}
      {route.map((router: MyRouteProps, index: number) => {
        return <SelfRoute key={'route' + index} {...router} />;
      })}
    </Switch>
  );
};

export default router;
