import React, { useState, FC, Dispatch, SetStateAction } from "react";
import { Input, Form as AntdForm, message } from "antd";
import { Modal, Form } from "components";
import { MyFormItemProps } from "components/form";
import { useIntl } from "react-intl";
import { userChangePassword, ChangePasswordType } from "server/buc/user";
import { pleaseEnter as e } from "util/comm";

const { Password } = Input;

interface Props {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

const ChangePassword: FC<Props> = (props) => {
  const { visible, setVisible } = props;
  const [loading, setLoading] = useState(false);
  const { formatMessage: f } = useIntl();
  const [form] = AntdForm.useForm();

  const formItems: MyFormItemProps[] = [
    {
      name: "oldPassword",
      dom: <Password placeholder={e({ id: "tx210007" })} />,
      label: f({ id: "tx210007", description: "旧密码" }),
      rules: [
        {
          required: true,
          message: e({ id: "tx210007" }),
        },
      ],
    },
    {
      name: "password",
      dom: <Password placeholder={e({ id: "tx210008" })} />,
      label: f({ id: "tx210008", description: "新密码" }),
      rules: [
        {
          required: true,
          message: e({ id: "tx210008" }),
        },
        {
          validator: (_rule, value, callback) => {
            let str: string | undefined = undefined;
            const reg = /^(?=.*\d)(?=.*[a-zA-Z]).{8,20}$/;
            if (!reg.test(value)) {
              str = f({
                id: "tx210010",
                description: "新密码8-20位必须至少包含一位数字和一个字母",
              });
            }
            callback(str);
          },
        },
      ],
    },
    {
      name: "verifyPassword",
      dom: <Password placeholder={e({ id: "tx210006" })} />,
      label: f({ id: "tx210006", description: "确认密码" }),
      rules: [
        {
          required: true,
          message: e({ id: "tx210006" }),
        },
        {
          validator: (_rule, value, callback) => {
            const password = form.getFieldValue("password");
            let str: string | undefined = undefined;
            if (value !== password) {
              str = f({
                id: "tx210009",
                description: "确认密码和新密码不一致",
              });
            } else {
              str = undefined;
            }
            callback(str);
          },
        },
      ],
    },
  ];

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  const handleOk = async () => {
    const formData = await form.validateFields();
    setLoading(true);
    const res = await userChangePassword(formData as ChangePasswordType);
    setLoading(false);
    if (res) {
      handleCancel();
      message.success(f({ id: "tx000024", description: "操作成功" }));
      window.location.href = "/login";
    }
  };

  return (
    <Modal
      title={f({ id: "tx000027", description: "修改密码" })}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={loading}
    >
      <Form
        form={form}
        items={formItems}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 16 }}
      />
    </Modal>
  );
};

export default ChangePassword;
