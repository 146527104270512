import React, { useEffect, useRef } from 'react';
import { Layout, Menu } from 'antd';
import { SiderProps as AntdSiderProps } from 'antd/es/layout/Sider';
import { useLocation, Link } from 'react-router-dom';
import useSetState from 'util/useSetState';
import { useIntl } from 'react-intl';
import { menuStore } from 'store';
import { useObserver } from 'mobx-react';

interface SiderProps extends AntdSiderProps {
  onCreated?: Function;
  /**
   * 菜单点击事件
   */
  menuClick?: (key: string) => void;
  /**
   * 控制菜单选中
   */
  selectKeys?: string;
}

interface MyState {
  menu: any[];
  menuData: any;
  openKeys: any[];
  selectedKeys: any[];
}

const { Sider } = Layout;
const { SubMenu, Item } = Menu;

function Index(props: SiderProps) {
  const { onCreated } = props;
  const WIDTH: number = 230;
  const menuRef: any = useRef();
  const { formatMessage: f } = useIntl();
  // const params: any = useParams()

  const [state, setState] = useSetState<MyState>({
    menu: [],
    menuData: null,
    openKeys: [],
    selectedKeys: [],
  });

  const location = useLocation();

  useEffect(() => {
    // 获取当前路由路径
    const pathName: string = location.pathname;
    // 获取当前路由顶级节点
    const fPathName = pathName.split('/')[2];

    const selfMenu = menuStore.menu.filter((m: any) => {
      return fPathName.includes(m.uiRoute);
    });
    menuRef.current = selfMenu;

    setState({
      menuData: selfMenu[0],
    });
    handleMenus(selfMenu[0]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.menuData) {
      // 获取当前路由路径
      const pathName: string = location.pathname;
      // 获取当前路由顶级节点
      const selfpathName = pathName.split('/').pop();

      const { children: Menus } = state.menuData;
      if (Menus) {
        // 获取当前的菜单分组
        const subMenus = Menus.find((item: any) => {
          if (item.children) {
            const childrenObj = item.children.find((child: any) => child.uiRoute === selfpathName);
            return !!childrenObj;
          }
          return false;
        });
        // 获取到当前分组对象
        if (subMenus && subMenus.uiRoute !== state.openKeys[0]) {
          setState({
            openKeys: [subMenus.uiRoute],
          });
        }
        setState({
          selectedKeys: [selfpathName],
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, state.menuData]);

  /**
   * 处理左侧菜单栏
   * @param menus
   */
  const handleMenus = (menus: any) => {
    const { children } = menus;
    if (!children) return;
    const arr = children.map((item: any) => {
      if (item.children) {
        return renderSubMenu(item);
      }
      return renderMenuItem(item);
    });
    setState({
      menu: arr,
    });

    // 默认第一个菜单选择或者菜单组下的第一个菜单为选择
    let selectName: string = '';
    if (children[0] && children[0].children) {
      selectName = children[0].children[0].uiRoute;
      setState({
        openKeys: [children[0].uiRoute],
        selectedKeys: [selectName],
      });
    } else if (children[0]) {
      selectName = children[0].uiRoute;
      setState({
        selectedKeys: [selectName],
      });
    }
    if (onCreated) {
      onCreated(selectName);
    }
  };

  /**
   * 生成分组菜单
   * @param item
   */
  const renderSubMenu = (item: any) => {
    return (
      <SubMenu key={item.uiRoute} title={<span>{f({ id: item.rightsName })}</span>}>
        {item.children.map((r: any) => renderMenuItem(r))}
      </SubMenu>
    );
  };

  /**
   * 生成单个菜单
   * @param item
   */
  const renderMenuItem = (item: any) => {
    return (
      <Item key={item.uiRoute}>
        <Link
          to={{
            pathname: item.path,
          }}
        >
          {f({ id: item.rightsName })}
        </Link>
      </Item>
    );
  };

  /**
   * 分组菜单展开事件
   * @param openKeys
   */
  const openMenu = (openKeys: string[]) => {
    const latestOpenKey = openKeys.find((key: string | number) => state.openKeys.indexOf(key) === -1);
    const arrMenu = menuRef.current[0].children;

    if (!!arrMenu.find((item: any) => item.path === latestOpenKey)) {
      setState({
        openKeys: openKeys,
      });
    } else {
      setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  return useObserver(() => (
    <Sider
      theme='light'
      width={menuStore.status ? 0 : WIDTH}
      style={{
        height: '100%',
        borderRight: 0,
        overflowY: 'auto',
        overflowX: 'clip',
      }}
    >
      <Menu mode='inline' selectedKeys={state.selectedKeys} openKeys={state.openKeys} onOpenChange={openMenu}>
        {state.menu}
      </Menu>
    </Sider>
  ));
}

export default Index;
